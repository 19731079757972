import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button, Modal, Divider } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { useAsyncAxios, utilAxiosWithAppToken, utilAxiosWithAuth } from "../../utils/customAxios";
import { useAuthState } from "../../provider/AuthProvider";
import { useMediaQuery } from "react-responsive";
import { allPlaces } from "./TravelSpot";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";

import moment from "moment";
import ImageSlider from "../../component/ImageSlider";
import Search from "../../component/rent/Search";
import GoodsCard from "../../component/rent/GoodsCard";
import ReviewCard from "../../component/rent/ReviewCard";
import MainCarousel from "../../component/rent/MainCarousel";
import TravelSpotCard from "../../component/rent/TravelSpotCard";
import RentVideoManualCard from "../../component/rent/RentVideoManualCard";
import KakaoMap from "../../component/KakaoMap";
import CustomSheet from "../../component/CustomSheet";
import ReviewSimpleCard from "../../component/rent/ReviewSimpleCard";

import Banner2 from "../../assets/images/home/rent/banner_main2.jpg";
import Banner3 from "../../assets/images/home/rent/banner_main3.jpg";
import Banner4 from "../../assets/images/home/rent/banner_main4.jpg";
import Banner6 from "../../assets/images/home/rent/banner_main6.jpg";
import Banner2Max from "../../assets/images/home/rent/banner_main_max2.jpg";
import Banner3Max from "../../assets/images/home/rent/banner_main_max3.jpg";
import Banner4Max from "../../assets/images/home/rent/banner_main_max4.jpg";
import Banner6Max from "../../assets/images/home/rent/banner_main_max6.jpg";
import MobileBanner2 from "../../assets/images/home/rent/banner_main_mobile2.jpg";
import MobileBanner3 from "../../assets/images/home/rent/banner_main_mobile3.jpg";
import MobileBanner4 from "../../assets/images/home/rent/banner_main_mobile4.jpg";
import MobileBanner6 from "../../assets/images/home/rent/banner_main_mobile6.jpg";

import Title from "../../component/rent/Title";
import NoData from "../../component/NoData";
import IconDigitalKey from "../../assets/images/home/rent/icon_home_digitalKey.png";
import IconEVCharger from "../../assets/images/home/rent/icon_evCharger.png";
import IconRight from "../../assets/images/home/rent/icon_right.svg";
import IconNaver from "../../assets/images/home/rent/icon_navi_naver.png";
import IconKakao from "../../assets/images/home/rent/icon_navi_kakao.png";
import IconTmap from "../../assets/images/home/rent/icon_tmap.png";
import CustomLoading from "../../component/rent/CustomLoading";

import NoEntryPopup from "../../component/rent/NoEntryPopup";
import HeaterCostEventPopup from "../../component/rent/HeaterCostEventPopup";

type RentsByDate = {
    [key: string]: Array<TypeDTO.RentDto>;
};

function Home() {
    const navigate = useNavigate();
    const userDetails = useAuthState();

    const [rentCarsCount, setRentCarsCount] = useState<number>();
    const [rents, setRentCars] = useState<Array<{ regionName: string; rentCars: Array<TypeDTO.RentCarDto> }>>([]);
    const [b2bRents, setB2bRentCars] = useState<Array<TypeDTO.RentCarDto>>([]);
    const [search, setSearch] = useState<TypeUtils.SearchType>();
    const [loading, setLoading] = useState<boolean>(true);
    const isMax = useMediaQuery({ minWidth: 1800 });

    const [openHeaterCostEventPopup, setOpenHeaterCostEventPopup] = useState(true);
    const [openNoEntryPopup, setOpenNoEntryPopup] = useState(true);

    const [availableRentCarCounts, setAvailableRentCarCounts] = useState(0);
    const [disableStartDates, setDisableStartDates] = useState<Array<string>>([]); // TODO: display calendar
    const [disableEndDates, setDisableEndDates] = useState<Array<string>>([]); // TODO: display calendar

    const [bestReviews, setBestReviews] = useState<Array<TypeDTO.BoardArticleDto>>([]);
    const [rentVideoManuals, setRentVideoManuals] = useState<Array<TypeDTO.RentVideoManualDto>>([]);
    const [rentPeriodLimits, setRentPeriodLimits] = useState<Array<TypeDTO.RentPeriodLimitDto>>();
    const [rentPeakSeasons, setRentPeakSeasons] = useState<Array<TypeDTO.RentPeakSeasonDto>>();

    const [banners, setBanners] = useState<Array<string>>([]);
    const [mobileBanners, setMobileBanners] = useState<Array<string>>([]);

    const [travelSpot, setTravelSpot] = useState<any>(null);

    useEffect(() => {
        requestGetRentCars();
        requestGetBoardArticles("1");
        requestGetRentVideoManuals();
        requestGetRentPeriodLimits();
        requestGetRentPeakSeasons();

        const today = moment().format(Common.FORMAT_DATE_TIME);

        // 팝업 날짜 여기서 수정 🔽
        const heaterCostEventPopupEndPeriod = moment("2024-11-01").format(Common.FORMAT_DATE_TIME);
        const heaterCostEventPopupClosePeriod = window.localStorage.getItem("heaterCostEventPopupClosePeriod");

        const noEntryPopupClosePeriod = window.localStorage.getItem("noEntryPopupClosePeriod");

        if (today > heaterCostEventPopupEndPeriod) {
            setOpenHeaterCostEventPopup(false);
        } else if (heaterCostEventPopupClosePeriod !== null) {
            const obj = JSON.parse(heaterCostEventPopupClosePeriod);
            const expire = moment(obj.expire).format(Common.FORMAT_DATE_TIME);

            if (expire > today) {
                setOpenHeaterCostEventPopup(false);
            } else {
                window.localStorage.removeItem("heaterCostEventPopupClosePeriod");
                setOpenHeaterCostEventPopup(true);
            }
        }

        if (noEntryPopupClosePeriod !== null) {
            const obj = JSON.parse(noEntryPopupClosePeriod);
            const expire = moment(obj.expire).format(Common.FORMAT_DATE_TIME);

            if (expire > today) {
                setOpenNoEntryPopup(false);
            } else {
                window.localStorage.removeItem("noEntryPopupClosePeriod");
                setOpenNoEntryPopup(true);
            }
        }
    }, []);

    useLayoutEffect(() => {
        if (moment().startOf("day").isSameOrBefore("2024-10-31")) {
            setBanners(isMax ? [Banner4Max, Banner6Max, Banner2Max, Banner3Max] : [Banner4, Banner6, Banner2, Banner3]);
            setMobileBanners([MobileBanner4, MobileBanner6, MobileBanner2, MobileBanner3]);
        } else {
            setBanners(isMax ? [Banner6Max, Banner2Max, Banner3Max] : [Banner6, Banner2, Banner3]);
            setMobileBanners([MobileBanner6, MobileBanner2, MobileBanner3]);
        }
    }, [isMax]);

    useEffect(() => {
        requestGetRentCars();
        requestGetAvailalbeRentCars(search?.regionId);
    }, [search]);

    const requestAxiosGetRentCars = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_CARS_URL, {
            params: {
                rentRegionId: search?.regionId === 0 ? undefined : search?.regionId,
                startDate: search?.period && search?.period[0],
                endDate: search?.period && search?.period[1],
            },
        });
        return response.data;
    };

    const {
        loading: loadingGetRentCars,
        error: errorGetRentCars,
        data: resultGetRentCars,
        execute: requestGetRentCars,
    } = useAsyncAxios(requestAxiosGetRentCars);

    useEffect(() => {
        setLoading(true);
    }, [loadingGetRentCars]);

    useEffect(() => {
        if (!resultGetRentCars) return;
        console.log("resultGetRentCars", resultGetRentCars);

        const rentCarsByRegion: Array<{ regionName: string; rentCars: Array<TypeDTO.RentCarDto> }> = [];
        let rentRegionId = 0;
        let rentCarsByRegionItem: { regionName: string; rentCars: Array<TypeDTO.RentCarDto> };
        let count = 0;

        resultGetRentCars.rentCars
            ?.filter((rent: TypeDTO.RentCarDto) => !rent.rentRegion.b2bId)
            .forEach((rent: TypeDTO.RentCarDto) => {
                if (rentRegionId != rent.rentRegion.rentRegionId) {
                    rentRegionId = rent.rentRegion.rentRegionId;
                    rentCarsByRegionItem = {
                        regionName: rent.rentRegion.rentRegionName,
                        rentCars: [],
                    };
                    rentCarsByRegion.push(rentCarsByRegionItem);
                }
                rentCarsByRegionItem.rentCars.push(rent);
                count++;
            });
        setRentCarsCount(count);
        setRentCars(rentCarsByRegion);
        setB2bRentCars(resultGetRentCars.rentCars?.filter((rent: TypeDTO.RentCarDto) => rent.rentRegion.b2bId));
        setLoading(false);
    }, [resultGetRentCars]);

    useEffect(() => {
        if (!errorGetRentCars) return;
        console.log("errorGetRentCars", errorGetRentCars);

        setLoading(false);
    }, [errorGetRentCars]);

    const requestAxiosGetAvailalbeRentCars = async (regionId: number) => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_CARS_URL, {
            params: {
                rentRegionId: regionId === 0 ? undefined : regionId,
            },
        });
        return response.data;
    };

    const {
        loading: loadingGetAvailalbeRentCars,
        error: errorGetAvailalbeRentCars,
        data: resultGetAvailalbeRentCars,
        execute: requestGetAvailalbeRentCars,
    } = useAsyncAxios(requestAxiosGetAvailalbeRentCars);

    useEffect(() => {
        if (!resultGetAvailalbeRentCars) return;

        setAvailableRentCarCounts(resultGetAvailalbeRentCars.rentCars?.length);
        requestGetRentReservations();
    }, [resultGetAvailalbeRentCars]);

    useEffect(() => {
        if (!errorGetAvailalbeRentCars) return;
        console.log("errorGetAvailalbeRentCars", errorGetAvailalbeRentCars);
    }, [errorGetAvailalbeRentCars]);

    const getAllStartDateBetweenDate = (startDate: string, endDate: string) => {
        const start = moment(startDate).clone();
        const dates = [];

        while (start.isBefore(moment(endDate))) {
            dates.push(start.format(Common.FORMAT_DATE));
            start.add(1, "days");
        }

        return dates;
    };

    const getAllEndDateBetweenDate = (startDate: string, endDate: string) => {
        const start = moment(startDate).add(1, "days").clone();
        const dates = [];

        while (start.isSameOrBefore(moment(endDate))) {
            dates.push(start.format(Common.FORMAT_DATE));
            start.add(1, "days");
        }

        return dates;
    };

    const requestAxiosGetRentReservations = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENTS_URL, {
            params: {
                rentRegionId: search?.regionId === 0 ? undefined : search?.regionId,
                startDate: moment().startOf("month").format("YYYY-MM-DD"),
            },
        });
        return response.data;
    };

    const {
        loading: loadingGetRentReservations,
        error: errorGetRentReservations,
        data: resultGetRentReservations,
        execute: requestGetRentReservations,
    } = useAsyncAxios(requestAxiosGetRentReservations);

    useEffect(() => {
        if (!resultGetRentReservations) return;

        const reservations: Array<TypeDTO.RentDto> = resultGetRentReservations.rents;
        const reservationByStartDate: RentsByDate = {};
        const reservationByEndDate: RentsByDate = {};

        reservations.forEach((reservation) => {
            if (reservation.rentCar.repairing || reservation.rentCar.waiting || reservation.rentCar.unavailable) return;

            const allStartDates = getAllStartDateBetweenDate(reservation.startDate, reservation.endDate);
            allStartDates.forEach((date) => {
                if (!reservationByStartDate[date]) reservationByStartDate[date] = [];
                reservationByStartDate[date].push(reservation);
            });

            const allEndDates = getAllEndDateBetweenDate(reservation.startDate, reservation.endDate);
            allEndDates.forEach((date) => {
                if (!reservationByEndDate[date]) reservationByEndDate[date] = [];
                reservationByEndDate[date].push(reservation);
            });
        });

        setDisableStartDates(Object.keys(reservationByStartDate).filter((date) => reservationByStartDate[date].length >= availableRentCarCounts));
        setDisableEndDates(Object.keys(reservationByEndDate).filter((date) => reservationByEndDate[date].length >= availableRentCarCounts));
    }, [resultGetRentReservations]);

    useEffect(() => {
        if (!errorGetRentReservations) return;
        console.log("errorGetRentReservations", errorGetRentReservations);
    }, [errorGetRentReservations]);

    const onClick = (carId: number, b2bId?: number | undefined | null) => {
        if (b2bId && b2bId !== userDetails?.user?.b2b?.b2bId) {
            Modal.warning({ centered: true, title: String.msg_onlyB2bMember, okText: String.confirm, onOk() {} });
            return;
        }
        !search?.period || !search?.period[1]
            ? navigate(Common.PAGE_RENT_DETAIL + "/" + carId + "/" + search?.regionId)
            : navigate(Common.PAGE_RENT_DETAIL + "/" + carId + "/" + search?.regionId + "/" + search?.period[0] + "/" + search?.period[1]);
    };

    const onChange = (value: TypeUtils.SearchType) => {
        setSearch({ regionId: value.regionId, period: value.period });
    };

    const goToDigitalKeyController = () => {
        if (!userDetails?.token) {
            Modal.info({
                centered: true,
                title: "디지털키",
                content: "로그인 후 사용할 수 있습니다.",
                okText: String.confirm,
                onOk() {},
            });
        } else {
            requestGetMyShareCarsForDigitalKey();
        }
    };

    const goToFindEVStation = () => {
        if (!userDetails?.token) {
            Modal.info({
                centered: true,
                title: "충전소 찾기",
                content: "로그인 후 사용할 수 있습니다.",
                okText: String.confirm,
                onOk() {},
            });
        } else {
            requestGetMyShareCarsForStation();
        }
    };

    const requestAxiosGetMyShareCars = async () => {
        const response = await utilAxiosWithAuth().get(Request.SHARER_MINE_URL);
        return response.data;
    };

    const {
        loading: loadingGetMyShareCarsForDigitalKey,
        error: errorGetMyShareCarsForDigitalKey,
        data: resultGetMyShareCarsForDigitalKey,
        execute: requestGetMyShareCarsForDigitalKey,
    } = useAsyncAxios(requestAxiosGetMyShareCars, false, false, true);

    useEffect(() => {
        if (!resultGetMyShareCarsForDigitalKey) return;
        if (resultGetMyShareCarsForDigitalKey.carSharers.length === 0) {
            Modal.info({
                centered: true,
                title: "디지털키",
                content: "공유받은 디지털 키가 없습니다. 이용 당일 차량 인도 후 이용하실 수 있습니다.",
                okText: "확인",
                onOk() {},
            });
        } else {
            navigate(Common.PAGE_DIGITALKEY_CONTROL);
        }
    }, [resultGetMyShareCarsForDigitalKey]);

    useEffect(() => {
        if (errorGetMyShareCarsForDigitalKey === null) return;

        console.log("errorGetMyShareCarsForDigitalKey", errorGetMyShareCarsForDigitalKey);
    }, [errorGetMyShareCarsForDigitalKey]);

    const {
        loading: loadingGetMyShareCarsForStation,
        error: errorGetMyShareCarsForStation,
        data: resultGetMyShareCarsForStation,
        execute: requestGetMyShareCarsForStation,
    } = useAsyncAxios(requestAxiosGetMyShareCars, false, false, true);

    useEffect(() => {
        if (!resultGetMyShareCarsForStation) return;
        console.log("resultGetMyShareCarsForStation", resultGetMyShareCarsForStation);

        if (resultGetMyShareCarsForStation.carSharers.length === 0) {
            Modal.info({
                centered: true,
                title: "충전소 찾기",
                content: "이용 당일 차량 인도 후 이용하실 수 있습니다.",
                okText: "확인",
                onOk() {},
            });
        } else {
            navigate(Common.PAGE_FIND_EV_STATION);
        }
    }, [resultGetMyShareCarsForStation]);

    useEffect(() => {
        if (!errorGetMyShareCarsForStation) return;

        console.log("errorGetMyShareCarsForStation", errorGetMyShareCarsForStation);
    }, [errorGetMyShareCarsForStation]);

    const requestAxiosGetBoardArticles = async (boardIds: string) => {
        const response = await utilAxiosWithAppToken().get(
            Request.BOARD_URL + "/" + Common.BEST_REVIEW_BOARD_ID + "-" + Common.USER_REVIEW_BOARD_ID,
            {
                params: { page: 0, size: 0 },
            }
        );
        return response.data;
    };

    const {
        loading: loadingGetBoardArticles,
        error: errorGetBoardArticles,
        data: resultGetBoardArticles,
        execute: requestGetBoardArticles,
    } = useAsyncAxios(requestAxiosGetBoardArticles);

    useEffect(() => {
        if (!resultGetBoardArticles) return;
        console.log("resultGetBoardArticles", resultGetBoardArticles);

        setBestReviews(resultGetBoardArticles.boardArticles.content);
    }, [resultGetBoardArticles]);

    useEffect(() => {
        if (!errorGetBoardArticles) return;

        console.log("errorGetBoardArticles", errorGetBoardArticles);
    }, [errorGetBoardArticles]);

    const requestAxiosGetRentVideoManuals = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_MANUAL_VIDEOS_URL);
        return response.data;
    };

    const {
        loading: loadingGetRentVideoManuals,
        error: errorGetRentVideoManuals,
        data: resultGetRentVideoManuals,
        execute: requestGetRentVideoManuals,
    } = useAsyncAxios(requestAxiosGetRentVideoManuals);

    useEffect(() => {
        if (!resultGetRentVideoManuals) return;
        console.log("resultGetRentVideoManuals", resultGetRentVideoManuals);
        setRentVideoManuals(resultGetRentVideoManuals.rentVideoManuals);
    }, [resultGetRentVideoManuals]);

    useEffect(() => {
        if (!errorGetRentVideoManuals) return;
        console.log("errorGetRentVideoManuals", errorGetRentVideoManuals);
    }, [errorGetRentVideoManuals]);

    const requestAxiosGetRentPeriodLimits = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_PERIOD_LIMITS_URL, {
            params: {
                startDate: moment().startOf("month").format("YYYY-MM-DD"),
            },
        });
        return response.data;
    };

    const {
        loading: loadingGetRentPeriodLimits,
        error: errorGetRentPeriodLimits,
        data: resultGetRentPeriodLimits,
        execute: requestGetRentPeriodLimits,
    } = useAsyncAxios(requestAxiosGetRentPeriodLimits);

    useEffect(() => {
        if (!resultGetRentPeriodLimits) return;
        console.log("resultGetRentPeriodLimits", resultGetRentPeriodLimits);

        setRentPeriodLimits(resultGetRentPeriodLimits.rentPeriodLimits);
    }, [resultGetRentPeriodLimits]);

    useEffect(() => {
        if (!errorGetRentPeriodLimits) return;

        console.log("errorGetRentPeriodLimits", errorGetRentPeriodLimits);
    }, [errorGetRentPeriodLimits]);

    const requestAxiosGetRentPeakSeasons = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_PEAK_SEASONS_URL, {
            params: {
                startDate: moment().startOf("month").format("YYYY-MM-DD"),
            },
        });
        return response.data;
    };

    const {
        loading: loadingGetRentPeakSeasons,
        error: errorGetRentPeakSeasons,
        data: resultGetRentPeakSeasons,
        execute: requestGetRentPeakSeasons,
    } = useAsyncAxios(requestAxiosGetRentPeakSeasons);

    useEffect(() => {
        if (!resultGetRentPeakSeasons) return;
        console.log("resultGetRentPeakSeasons", resultGetRentPeakSeasons);

        setRentPeakSeasons(resultGetRentPeakSeasons.rentPeakSeasons);
    }, [resultGetRentPeakSeasons]);

    useEffect(() => {
        if (!errorGetRentPeakSeasons) return;

        console.log("errorGetRentPeakSeasons", errorGetRentPeakSeasons);
    }, [errorGetRentPeakSeasons]);

    const sendMapUrl = useCallback(
        (type: "naver" | "tmap" | "kakao") => {
            let uri = "";

            if (type === "naver") {
                uri = `nmap://route/car?dlat=${travelSpot?.latitude}&dlng=${travelSpot?.longitude}&dname=${travelSpot?.address}`;
            } else if (type === "tmap") {
                uri = `tmap://route?goalname=${travelSpot?.address}&goalx=${travelSpot?.longitude}&goaly=${travelSpot?.latitude}`;
            } else if (type === "kakao") {
                uri = `kakaomap://route?ep=${travelSpot?.latitude},${travelSpot?.longitude}&by=CAR`;
            }

            window.location.href = uri;
        },
        [travelSpot]
    );

    return (
        <>
            {openNoEntryPopup && (
                <NoEntryPopup
                    open={true}
                    onChangedOpen={(opened) => {
                        setOpenNoEntryPopup(opened);
                    }}
                    className={!openHeaterCostEventPopup ? "" : "secondPopup"}
                />
            )}
            {openHeaterCostEventPopup && (
                <HeaterCostEventPopup
                    open={true}
                    onChangedOpen={(opened) => {
                        setOpenHeaterCostEventPopup(opened);
                    }}
                />
            )}
            <MobileView className="mobile">
                <div style={{ minHeight: "calc(100vh - 70px)", display: "flex", flexDirection: "column" }}>
                    <ImageSlider style={{ width: "auto", textAlign: "center" }} images={mobileBanners} />

                    {bestReviews?.length > 0 && (
                        <>
                            <div className="main-subtitle">
                                <h4>이용 후기</h4>
                                <Link to={Common.PAGE_RENT_BEST_REVIEW}>
                                    전체보기 <img src={IconRight} />
                                </Link>
                            </div>
                            <div className="simple-slider" style={{ marginTop: 0 }}>
                                {bestReviews.map((review) => (
                                    <ReviewSimpleCard key={review.boardArticleId} boardArticle={review} />
                                ))}
                            </div>
                            <Divider className="main-divider" />
                        </>
                    )}

                    <div className="space-page" style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <div className="main-subtitle" style={{ margin: "0 0 16px" }}>
                            <h4>RUTA40 렌트</h4>
                        </div>
                        <Search
                            type="home"
                            disabledDates={disableStartDates}
                            rentPeriodLimits={rentPeriodLimits}
                            rentPeakSeasons={rentPeakSeasons}
                            onChange={onChange}
                        />
                        {loading ? (
                            <CustomLoading />
                        ) : rents?.length > 0 ? (
                            <>
                                <div className="mt-3 mb-3">
                                    {rents.map(({ rentCars }) =>
                                        rentCars.map((rentCar, index) => (
                                            <div key={rentCar.rentCarId} onClick={() => onClick(rentCar.rentCarId)}>
                                                <Title
                                                    searchType={true}
                                                    info={{
                                                        regionName: {
                                                            content: rentCar?.rentRegion?.rentRegionName,
                                                        },
                                                        title: {
                                                            content: rentCar?.title,
                                                        },
                                                        priceDiscounted: {
                                                            content: rentCar?.discountPrice,
                                                        },
                                                        price: {
                                                            content: rentCar?.price,
                                                        },
                                                        tags: rentCar?.rentRestrictions,
                                                    }}
                                                />
                                                {index + 1 < rentCars.length && <Divider />}
                                            </div>
                                        ))
                                    )}
                                </div>
                                {b2bRents?.length > 0 && (
                                    <>
                                        <Divider style={{ borderTopColor: "#ccc" }} />
                                        <div className="main-subtitle" style={{ margin: "0 0 16px" }}>
                                            <h4>단체 전용 렌트</h4>
                                        </div>
                                        <div>
                                            {b2bRents.map((rentCar, index) => (
                                                <div key={rentCar.rentCarId} onClick={() => onClick(rentCar.rentCarId, rentCar.rentRegion.b2bId)}>
                                                    <Title
                                                        searchType={true}
                                                        info={{
                                                            regionName: {
                                                                content: rentCar?.rentRegion?.rentRegionName,
                                                            },
                                                            title: {
                                                                content: rentCar?.title,
                                                            },
                                                            priceDiscounted: {
                                                                content: rentCar?.discountPrice,
                                                            },
                                                            price: {
                                                                content: rentCar?.price,
                                                            },
                                                            tags: rentCar?.rentRestrictions,
                                                        }}
                                                    />
                                                    {index + 1 < b2bRents.length && <Divider />}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <NoData msg="해당 조건의 차량이 없습니다. 다른 조건으로 검색해 주세요." />
                        )}
                    </div>

                    <Divider className="main-divider" style={{ marginTop: 6 }} />

                    <div className="main-subtitle">
                        <h4>추천 여행지</h4>
                        <Link to={Common.PAGE_TRAVEL_SPOT}>
                            전체보기 <img src={IconRight} />
                        </Link>
                    </div>
                    <div className="simple-slider" style={{ marginTop: 0 }}>
                        {allPlaces.map((place, index) => (
                            <div key={index} className="travel-spot-item">
                                <KakaoMap
                                    mapCenter={{
                                        lat: Number(place?.latitude ?? 0),
                                        lng: Number(place?.longitude ?? 0),
                                    }}
                                    mapStyle={{ height: "165.5px", width: "100%" }}
                                    rentalLocation={{ list: place }}
                                    guideRegion={place.region ? place.region : undefined}
                                    guideMarker={place.marker ? place.marker : undefined}
                                />
                                <div className="travel-spot-summary">
                                    <div className="travel-spot-name">
                                        <div>{place?.rentRegionDescription}</div>
                                        <Button
                                            type="text"
                                            className="h-100 p-0"
                                            style={{ fontSize: 12, fontWeight: "bold", color: "#005FDB", float: "right" }}
                                            onClick={() => setTravelSpot(place)}
                                        >
                                            {String.navigation}
                                        </Button>
                                    </div>
                                    <div className="travel-spot-address">{place.address}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <CustomSheet
                        isOpen={travelSpot !== null}
                        content={
                            <div style={{ padding: "0 1.5rem 1.5rem 1.5rem", overflowX: "hidden" }}>
                                <h5 className="mb-3">{String.linkProgram}</h5>
                                <div className="px-1 text-center" style={{ display: "flex", justifyContent: "space-around" }}>
                                    <a className="h-100" onClick={() => sendMapUrl("naver")} target="_self" rel="noreferrer">
                                        <img src={IconNaver} width={60} />
                                        <p className="text-black">{String.naverMap}</p>
                                    </a>
                                    <a className="h-100" onClick={() => sendMapUrl("tmap")} target="_self" rel="noreferrer">
                                        <img src={IconTmap} width={60} style={{ marginBottom: "6px" }} />
                                        <p className="text-black">{String.tMap}</p>
                                    </a>
                                    <a className="h-100" onClick={() => sendMapUrl("kakao")} target="_self" rel="noreferrer">
                                        <img src={IconKakao} width={60} />
                                        <p className="text-black">{String.kakaoMap}</p>
                                    </a>
                                </div>
                            </div>
                        }
                        snapHeight={200}
                        onClose={() => setTravelSpot(null)}
                    />

                    <Divider className="main-divider" />

                    <div className="main-subtitle">
                        <h4>매뉴얼</h4>
                        <Link to={Common.PAGE_RENT_MANUAL}>
                            전체보기 <img src={IconRight} />
                        </Link>
                    </div>
                    <div className="simple-slider" style={{ marginTop: 0, marginBottom: 50 }}>
                        {rentVideoManuals.map((rentVideoManuals, index) => (
                            <div className="rent-video-manual-item" key={index}>
                                <RentVideoManualCard rentVideoManual={rentVideoManuals} />
                            </div>
                        ))}
                    </div>

                    <Button
                        className="p-0 btn-dark"
                        type="primary"
                        style={{ width: "42px", height: "42px", position: "fixed", bottom: "66px", right: "14px", zIndex: 4 }}
                        onClick={() => goToFindEVStation()}
                    >
                        <img src={IconEVCharger} width={20} height={22} style={{ marginBottom: 1 }} />
                    </Button>
                    <Button
                        className="p-0 btn-dark"
                        type="primary"
                        style={{ width: "42px", height: "42px", position: "fixed", bottom: "14px", right: "14px", zIndex: 4 }}
                        onClick={() => goToDigitalKeyController()}
                    >
                        <img src={IconDigitalKey} width={20} height={22} style={{ marginLeft: 3, marginBottom: 1 }} />
                    </Button>
                </div>
            </MobileView>
            <BrowserView className="browser">
                <div style={{ minHeight: "calc(100vh - 70px)", width: "100vw", marginBottom: 50 }}>
                    <div className="banner">
                        <ImageSlider style={{ textAlign: "center", objectFit: "cover" }} images={banners} />
                    </div>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className={`fadeIn p-4`}
                    >
                        {bestReviews?.length > 0 && (
                            <>
                                <div className="main-subtitle" style={{ marginTop: 16 }}>
                                    <h4>이용 후기</h4>
                                    <Link to={Common.PAGE_RENT_BEST_REVIEW}>
                                        전체보기 <img src={IconRight} />
                                    </Link>
                                </div>
                                <MainCarousel autoPlay={false}>
                                    {bestReviews.map((review) => (
                                        <div className="embla__slide review" key={review.boardArticleId}>
                                            <ReviewCard boardArticle={review} />
                                        </div>
                                    ))}
                                </MainCarousel>
                            </>
                        )}

                        <h4 style={{ marginTop: "40px", marginBottom: "20px" }}>RUTA40 렌트</h4>
                        <Search
                            onChange={onChange}
                            disabledDates={disableStartDates}
                            rentPeriodLimits={rentPeriodLimits}
                            rentPeakSeasons={rentPeakSeasons}
                        />
                        {loading ? (
                            <CustomLoading />
                        ) : Object.keys(rents).length ? (
                            <>
                                {rents.map(({ regionName, rentCars }) => (
                                    <div key={regionName}>
                                        <Row gutter={10}>
                                            {rentCars.map((rent) => (
                                                <Col span={8} key={rent.rentCarId}>
                                                    <GoodsCard rentCar={rent} onClick={onClick} />
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}
                                {b2bRents?.length > 0 && (
                                    <div>
                                        <h4 style={{ marginTop: "40px", marginBottom: "20px" }}>단체 전용 렌트</h4>
                                        <Row gutter={10} className="mt-3 mx-0">
                                            {b2bRents.map((rent) => (
                                                <Col span={8} key={rent.rentCarId}>
                                                    <GoodsCard rentCar={rent} onClick={onClick} />
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                )}
                            </>
                        ) : (
                            <NoData msg="해당 조건의 차량이 없습니다. 다른 조건으로 검색해 주세요." />
                        )}

                        <div className="main-subtitle">
                            <h4>추천 여행지</h4>
                            <Link to={Common.PAGE_TRAVEL_SPOT}>
                                전체보기 <img src={IconRight} />
                            </Link>
                        </div>
                        <MainCarousel autoPlay={false}>
                            {allPlaces.map((place, index) => (
                                <div className="embla__slide travel-spot" key={index}>
                                    <TravelSpotCard place={place} />
                                </div>
                            ))}
                        </MainCarousel>

                        {bestReviews?.length > 0 && (
                            <>
                                <div className="main-subtitle">
                                    <h4>매뉴얼</h4>
                                    <Link to={Common.PAGE_RENT_MANUAL}>
                                        전체보기 <img src={IconRight} />
                                    </Link>
                                </div>
                                <MainCarousel autoPlay={false}>
                                    {rentVideoManuals.map((rentVideoManuals, index) => (
                                        <div className="embla__slide rent-video-manual" key={index}>
                                            <RentVideoManualCard rentVideoManual={rentVideoManuals} />
                                        </div>
                                    ))}
                                </MainCarousel>
                            </>
                        )}
                    </Col>
                </div>
            </BrowserView>
        </>
    );
}

export default Home;
